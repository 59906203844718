import * as React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql, useStaticQuery } from "gatsby"

const SignupComplete = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="px-4 mt-8 text-slate-400">
        <Head title={`Signup complete | ${data.site.siteMetadata.title}`} />
        <h1 className="text-slate-200 text-5xl font-bold mb-4">
          You're in{" "}
          <span role="img" aria-label="emoji">
            🙌
          </span>
        </h1>
        <p className="text-lg text-slate-300 mb-4">
          Thanks for signing up! I'll make sure to keep you updated when I post
          new stuff on here.
        </p>
        <p>
          I love to connect with and talk to like-minded people. Here are a few
          ways to reach me:
        </p>
        <ul className="list-disc pl-4 my-2">
          <li>
            Linkedin:{" "}
            <a
              className="underline"
              href="https://www.linkedin.com/in/lasserravn/"
            >
              lasserravn
            </a>
          </li>
          <li>
            Twitter:{" "}
            <a className="underline" href="https://twitter.com/lssrvn1">
              lssrvn1
            </a>
          </li>
          <li>Email: lasserravn (at) gmail.com</li>
        </ul>
      </div>
    </Layout>
  )
}

export default SignupComplete
